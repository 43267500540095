<template>
  <base-modal @close="close" loadingMessage="Uploading" :loading="isUploading" dataCy="upload-csv-modal">
    <div class="csv-uploader-modal rounded-3xl p-8">
      <div class="grid gap-6">
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-2">
            <div>
              <inline-svg class="h-6 w-6" src="/img/icons/new/upload-doc.svg" />
            </div>
            <span class="text-2xl font-bold">Upload CSV</span>
          </div>

          <button @click="$emit('close')">
            <inline-svg src="/img/icons/close.svg" />
          </button>
        </div>
        <p class="text-lg">Batch upload tweets to your Queue.</p>

        <collapse>
          <new-collapse-item title="Read instructions" class="flex flex-col items-start">
            <div class="p-4 pb-0">
              <ul class="list-disc">
                <li>The format is very simple: one tweet per line.</li>
                <li>Posts will be scheduled according to your current schedule.</li>
                <li>
                  Tweets longer than 280 characters will be split into a thread (unless you're a
                  verified Twitter user).
                </li>
                <li>You can only schedule <i>one year</i> of tweets.</li>
                <li>The CSV file should not include a header and must be encoded in UTF-8.</li>
              </ul>
            </div>
          </new-collapse-item>
        </collapse>

        <dropzone-file-upload v-model="csvFile" :options="uploadOptions"></dropzone-file-upload>

        <div class="flex items-center space-x-2" v-if="parsedCSVLength > 0">
          <inline-svg src="/img/icons/tick.svg" />
          <span data-cy="csv-tweets-found">{{ parsedCSVLength }} tweets found</span>
        </div>

        <new-alert type="danger" v-if="formError">
          {{ formError }}
        </new-alert>

        <div class="row space-y-2">
          <div class="flex items-center justify-between">
            <span class="app-modal-text text-base font-medium text-gray-80">Set categories</span>

            <base-toggle
              name="csv-set-categories"
              v-model="showCategoryBlock"
              id="csv-set-categories"
              data-cy="csv-set-categories"
            />
          </div>
          <div class="flex items-center justify-between" v-if="showCategoryBlock">
            <div class="flex items-center">
              <span class="app-modal-text text-base font-medium text-gray-80">
                Upload only to Recurrent Posts
              </span>
              <info-tooltip content="Tweets won't be added to your Queue"></info-tooltip>
            </div>

            <base-toggle
              name="csv-set-categories"
              v-model="setPostsAsRecurrentPosts"
              id="csv-set-posts-as-recurrent-posts"
            />
          </div>
          <div class="py-2" v-if="showCategoryBlock && userCategories">
            <div class="flex flex-wrap gap-2">
              <new-base-checkbox
                v-for="category in userCategories"
                :key="category.id"
                :inputValue="category.id"
                :label="category.name"
                :title="category.name"
                v-model="selectedCategories"
                type="tag"
                data-cy="category-checkbox"
              />
            </div>
            <new-category-mini-form
              v-if="userCategories.length === 0"
              @new-category-added="autoSelectNewCategory"
            />
          </div>

          <div class="mt-3 flex items-center justify-between">
            <span class="app-modal-text text-base font-medium text-gray-80">
              Enable cross-posting
            </span>
            <base-toggle
              name="csv-enable-cross-posting"
              v-model="enableCrossPosting"
              id="csv-enable-cross-posting"
              data-cy="csv-enable-cross-posting"
            />
          </div>
          <div class="py-2" v-if="enableCrossPosting">
            <div class="flex flex-wrap gap-2">
              <template v-for="option in crossPostOptions">
                <tooltip
                  :key="option.value"
                  :content="
                    !option.isConnected
                      ? `Connect your ${option.label} account to cross-post to ${option.label}.`
                      : ''
                  "
                  :disabled="option.isConnected"
                >
                  <new-base-checkbox
                    :disabled="!option.isConnected"
                    :inputValue="option.value"
                    :label="option.label"
                    v-model="crossPostingTargets"
                    type="tag"
                  />
                </tooltip>
              </template>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-between">
          <span class="app-modal-text mr-2 text-base font-medium text-gray-80"
            >Set the tweets as Evergreen</span
          >
          <base-toggle
            name="csv-set-as-evergreen"
            v-model="setPostsAsFavorite"
            id="csv-set-as-evergreen"
            data-cy="csv-set-as-evergreen"
          />
        </div>

        <div class="flex items-center justify-end">
          <new-button
            @click="isGrowthPage ? uploadGrowthProgramCSV() : uploadCSV()"
            :class="parsedCSVLength > 0 ? '' : 'disabled'"
            :disabled="!canUploadCSV"
            data-cy="csv-upload-button"
          >
            Upload
          </new-button>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
  import dao from '@/dao';
  import storage from '@/storage';
  import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload';
  import moment from 'moment';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { DraftThread } from '@/models/DraftThread';
  import { mapState, mapGetters } from 'vuex';
  import { store } from '@/store';
  import ThreadMixin from '../Mixins/ThreadMixin.vue';
  import NewCategoryMiniForm from '@/components/NewCategoryMiniForm.vue';
  import LinkedInMixin from '@/views/Mixins/LinkedInMixin.vue';
  import FacebookMixin from '@/views/Mixins/FacebookMixin.vue';
  import InstagramMixinVue from '@/views/Mixins/InstagramMixin.vue';
  import BreakpointsMixin from '@/views/Mixins/BreakpointsMixin.vue';
  import Collapse from '@/components/Collapse/Collapse';
  import NewCollapseItem from '@/components/Collapse/NewCollapseItem';

  const fb = require('../../firebase');
  const Papa = require('papaparse');

  export default {
    components: {
      DropzoneFileUpload,
      NewCategoryMiniForm,
      Collapse,
      NewCollapseItem,
    },
    computed: {
      ...mapState(['userCategories']),
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      crossPostOptions() {
        return [
          {
            label: 'Facebook',
            value: 'facebook',
            isConnected: Boolean(this.isFacebookPageConnected),
          },
          {
            label: 'LinkedIn',
            value: 'linkedin',
            isConnected: Boolean(this.isUserConnectedToLinkedIn),
          },
          {
            label: 'Instagram',
            value: 'instagram',
            isConnected: Boolean(this.isUserConnectedToInstagram),
          },
        ];
      },
      canUploadCSV() {
        if (this.showCategoryBlock) {
          return this.parsedCSVLength > 0 && this.selectedCategories.length && !this.isUploading;
        }
        return this.parsedCSVLength > 0 && !this.isUploading;
      },
    },
    data() {
      return this.initialState();
    },
    mounted() {
      this.uploadOptions.dictDefaultMessage = this.isSmallScreen
        ? 'Select a CSV file'
        : 'Drop a CSV file here';
    },
    beforeDestroy() {
      this.listOfSnapshotsUnsubscriptions.forEach((unsubscribe) => {
        if (!unsubscribe) return;
        unsubscribe();
      });
    },
    methods: {
      close() {
        if (this.isUploading) return;
        Object.assign(this.$data, this.initialState());
        this.$emit('close');
      },
      initialState() {
        return {
          csvFile: [],
          formError: null,
          isUploading: false,
          parsedCSVLength: null,
          setPostsAsFavorite: false,
          threadsFromCSV: null,
          uploadOptions: {
            dictDefaultMessage: 'Drop a CSV file here',
            acceptedFiles: '.csv',
            autoProcessQueue: false,
          },
          selectedCategories: [],
          enableCrossPosting: false,
          crossPostingTargets: [],
          setPostsAsRecurrentPosts: false,
          listOfSnapshotsUnsubscriptions: [],
        };
      },
      closeModalIfTheCSVFileWasProcessed: function (fileId) {
        const unsubscribe = fb.csvUploadsCollection.doc(fileId).onSnapshot((snapshot) => {
          const { processed } = snapshot.data();
          if (!processed) return;
          this.isUploading = false;
          this.close();
          this.$notify({ type: 'success', message: 'CSV file successfully processed!' });
        });

        this.listOfSnapshotsUnsubscriptions.push(unsubscribe);
        store.dispatch('addFirestoreListener', unsubscribe);
      },
      showDuplicateConfirmationAlert() {
        return this.swalModal({
          title: 'Upload again?',
          text: `You have already uploaded a file with the same name in the last 24 hours. Are you sure you want to upload this file again?`,
          type: 'warning',
          showCancelButton: true,
          focusCancel: true,
          focusConfirm: false,
          confirmButtonText: 'Yes, upload again',
        }).then((result) => result.value);
      },
      wasCSVFileUploadedLessThanOneDayAgo: function (userId, fileName) {
        return dao.csvUpload
          .getLatestCSVUploadWithSameName(fileName, userId)
          .then((csvFile) => {
            if (!csvFile) {
              return false;
            }
            const csvUploadDateOneDayLater = moment(csvFile.time.toDate()).add(1, 'days');
            return moment() < csvUploadDateOneDayLater;
          })
          .catch((error) => {
            console.error(error);
            return false;
          });
      },
      saveCSVUploadInfo: function (fileName, fileId, size) {
        return dao.csvUpload.createCSVUpload(
          fileId,
          false,
          size,
          fb.usersCollection.doc(this.userProfile.uid),
          new Date(),
          fileName,
          this.setPostsAsFavorite,
          this.categories,
          this.enableCrossPosting ? this.crossPostingTargets : [],
          this.setPostsAsRecurrentPosts
        );
      },
      uploadCSV: function () {
        if (!this.parsedCSVLength) return;

        this.$eventStore.uploadCSV();

        const self = this;

        const userId = this.userProfile.uid;

        const fileName = this.csvFile[0].name;
        const metadata = { userId, fileName };

        function storeCSV() {
          return storage
            .uploadCSVFile(self.csvFile[0], metadata)
            .then((result) => {
              const fileId = result.metadata.generation;
              const dbFileName = `csv-uploads/${userId}-${fileName}`;
              self
                .saveCSVUploadInfo(dbFileName, fileId, self.parsedCSVLength)
                .then(() => {
                  self.closeModalIfTheCSVFileWasProcessed(fileId);
                })
                .catch((error) => {
                  console.error(
                    `An error has occurred while saving the CSV upload info in Firestore.`,
                    error
                  );
                  throw error;
                });
            })
            .catch((error) => {
              console.error(error);
              self.swalModal({
                title: 'Sorry',
                text: 'An error has occurred while uploading the CSV file.',
                type: 'warning',
              });
              self.isUploading = false;
            });
        }

        this.wasCSVFileUploadedLessThanOneDayAgo(userId, fileName).then((isItDuplicate) => {
          if (!isItDuplicate) {
            this.isUploading = true;
            storeCSV();
          } else {
            this.showDuplicateConfirmationAlert().then((isItConfirmed) => {
              if (isItConfirmed) {
                this.isUploading = true;
                storeCSV();
              } else {
                self.isUploading = false;
                self.close();
              }
            });
          }
        });
      },
      uploadGrowthProgramCSV: function () {
        this.threadsFromCSV.forEach((tweet) => {
          const tweetToSave = [
            {
              status: tweet[0],
              count: 0,
              media: null,
              mediaFile: null,
              published: false,
            },
          ];
          const thread = new DraftThread({
            tweets:tweetToSave,
            user: fb.usersCollection.doc(this.selectedUser.uid),
            source: 'growth-program',
            writer: fb.usersCollection.doc(this.userProfile.uid),
            growthProgram: this.selectedGrowthProgram.ref
          });
          return thread.saveToFirestore(this.userProfile.timezone, this.$eventStore);
        });
        this.close();
      },
    },
    name: 'UploadCSVModal',
    props: {
      show: Boolean,
      selectedUser: Object,
      isGrowthPage: Boolean,
      selectedGrowthProgram: Object,
    },
    watch: {
      csvFile(newValue) {
        this.parsedCSVLength = null;

        if (newValue.length === 0) return;

        const file = newValue[0];

        // Sometimes the mime type is empty (caused by OpenOffice in my experience)
        if (!['', 'text/csv', 'application/vnd.ms-excel'].includes(file.type)) {
          this.formError = 'Not a CSV file.';
          return;
        }

        if (file.size > 10 * 1024 * 1024) {
          this.formError = 'The file is too big.';
          return;
        }

        Papa.parse(file, {
          skipEmptyLines: true,
          delimiter: ',',
          complete: (result) => {
            const tweetsTooLarge = result.data.filter((t) => t.length > 280 * 100);
            if (result.errors.length > 0) {
              this.formError = `Can't parse this file. Make sure it's valid CSV.`;
            } else if (tweetsTooLarge.length !== 0) {
              this.formError = `Some tweets you are trying to schedule are too long.`;
            } else {
              this.threadsFromCSV = result.data;
              this.parsedCSVLength = result.data.length;
              this.formError = null;
            }
          },
        });
      },
    },
    mixins: [
      SwalModalMixin,
      ThreadMixin,
      LinkedInMixin,
      FacebookMixin,
      InstagramMixinVue,
      BreakpointsMixin,
    ],
  };
</script>

<style lang="scss" scoped>
  .dz-message {
    padding: 1rem 1rem !important;
  }
  @supports (padding: max(0px)) {
    form {
      padding-bottom: env(safe-area-inset-bottom) !important;
    }
  }
  .csv-uploader-modal {
    width: 100%;
    max-width: 466px;
    @include sm {
      width: 1024px;
    }
  }
</style>
